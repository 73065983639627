import React from 'react';

import { IRenderWidgetProps } from '../../../assets/modules/renderWidget/model';
import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { getInitialiser } from '../../modules/getInitialiser';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../types/GetJSON';
import { MerchantLinkData } from '../../types/MerchantLinkData';
import { WidgetInitialiserOptions } from '../../types/WidgetInitialiser';
import type { WidgetProps } from '../../types/WidgetProps';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import PromotionItem from './PromotionItem';
import PromotionMain from './PromotionMain';

loadCSS('promotion');

const Promotion: React.FC<WidgetProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetStandard },
  } = props;
  return (
    <HawkWidgetStandard {...props}>
      <Consumer>
        {(value): JSX.Element => {
          const { dealData } = value;
          const deals = dealData && dealData.deals ? dealData.deals : [];

          return (
            <PromotionMain>
              {deals.map((deal) => (
                <PromotionItem
                  {...{
                    deal,
                    key: deal?.id,
                  }}
                />
              ))}
            </PromotionMain>
          );
        }}
      </Consumer>
    </HawkWidgetStandard>
  );
};

Promotion.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: MerchantLinkData,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default Promotion;
