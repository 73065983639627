import Uri from 'urijs';

import type { Deal } from '../../../../types/Deal';
import getCurrencySymbol from '../../../../utils/getCurrencySymbol';
import getImageUrl from '../../../getImageUrl';

export const getFallbackOffer = ({
  dataLinkMerchant,
  link,
  linkText,
  localiser,
  modelName,
  widgetId,
  editorialCurrency,
  editorialPrice,
  editorialWasPrice,
}): Deal => {
  const elDomain = dataLinkMerchant?.name ?? (link ? new Uri(link).domain() : 'merchant');
  const ctaDealWidget = linkText || localiser.translate('alternativeLinkText');

  return {
    an: dataLinkMerchant?.network || null,
    bundle_models: [],
    click_count: 0,
    click_count_weekly: 0,
    click_count_monthly: 0,
    image: null,
    label_image: null,
    last_update: 0,
    mobile_match_score: 0,
    model_id: 0,
    percentage: 0,
    popularity: 0,
    product_key: '',
    score: 0,
    shipping: {
      prime: false,
      url: null,
    },
    id: 0,
    match_id: 0,
    product_type: 300,
    offer: {
      link,
      name: modelName,
      link_text: widgetId === 'deal' ? ctaDealWidget : linkText,
      merchant_link_text: widgetId === 'deal' ? ctaDealWidget : linkText,
      label: localiser.translate('offerLabelNoPrice'),
      price: editorialPrice || '0.00',
      in_stock: true,
      stock_quantity: null,
      currency_iso: editorialCurrency || 'GBP',
      currency_symbol: (editorialCurrency && getCurrencySymbol(editorialCurrency)) || '£',
      percentage_saving: null,
      percentage_saving_label: null,
      money_saving: null,
      money_saving_label: null,
      display_name: modelName,
      display_labels: '',
      was_price: editorialWasPrice,
    },
    merchant: {
      id: dataLinkMerchant?.id || null,
      name: elDomain,
      url: dataLinkMerchant?.url || null,
      logo_url: '',
      territory: '',
      preference: 0,
    },
    model: modelName,
    model_matched: [modelName],
    model_image: getImageUrl('misc/blank.png'),
  };
};
